$primary: #212529;
$accent: #f29200;
$warn: #f44336;
$textColor2: #FED136;

$timeslot-background-color:rgba(223, 239, 255);
$timeslot-text-color:#060688;
$timeslot-empty-border-color: #47c401;
$timeslot-partial-border-color: #ebb000;
$timeslot-full-border-color: rgba(253, 0, 0, 0.94);
$timeslot-border-color:#000a9e;

$appointment-background-color:#ecffec;
$appointment-text-color:#025402;
$appointment-border-color:green;

