@import 'styles/roboto.scss';
@import 'styles/material-icons.scss';
@import 'styles/font-awesome_5-11-2.min.css';
@import "src/_zf-theme.scss";
@import "src/_zf-fullcalendar.scss";

html, body {
  height: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.cal-week-view .cal-time-events .cal-day-column {
  margin-right: 20px;
}

.cal-week-view .cal-hour {
  width: calc(100% + 20px);
}

.section {
  border: 1px solid rgba(0,0,0,.03);
  box-shadow: 0 2px 2px rgba(0,0,0,.24), 0 0 2px rgba(0,0,0,.12);

  margin: 4px;
  font-weight: 400;
  border-radius: 10px;
  background-color: white;

  .header {
    display: grid;
    place-items: center;
    padding: 10px 0;
    color: $accent;
    background: rgba(0,0,0,.03);
    font-size: 1.5em;
  }

  .body {
    padding: 30px;
    color: rgba(0,0,0,.87);
  }

  .actions {
    display: grid;
    place-items: center;
    padding-bottom: 1em;
  }
}

.mat-radio-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}

.mat-radio-button {
  margin: 5px;
}

.canceled span {
  color: #ff3d7f !important;
  text-decoration: line-through;
}

.mat-row:hover {
  background-color: #f4f2f2;
}

.inactive {
  color: lightgray;
}

.pulsate {
  -webkit-animation: pulsate 4s ease-out;
  -webkit-animation-iteration-count: infinite;
  opacity: 0;
}
@-webkit-keyframes pulsate {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1.0;
  }
  100% {
    opacity: 0.3;
  }
}



