$zf-yellow: (
  50 : #f29200,
  100 : #f29200,
  200 : #f29200,
  300 : #f29200,
  400 : #f29200,
  500 : #f29200,
  600 : #f29200,
  700 : #f29200,
  800 : #f29200,
  900 : #f29200,
  A100 : #f29200,
  A200 : #f29200,
  A400 : #f29200,
  A700 : #f29200,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

/* For use in src/lib/core/theming/_palette.scss */
$zf-black: (
  50 : #212529,
  100 : #212529,
  200 : #212529,
  300 : #212529,
  400 : #212529,
  500 : #212529,
  600 : #212529,
  700 : #212529,
  800 : #212529,
  900 : #212529,
  A100 : #212529,
  A200 : #212529,
  A400 : #212529,
  A700 : #212529,
  contrast: (
    50 : #FED136,
    100 : #FED136,
    200 : #FED136,
    300 : #FED136,
    400 : #FED136,
    500 : #FED136,
    600 : #FED136,
    700 : #FED136,
    800 : #FED136,
    900 : #FED136,
    A100 : #FED136,
    A200 : #FED136,
    A400 : #FED136,
    A700 : #FED136,
  )
);

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$zf-primary: mat-palette($zf-black);
$zf-accent: mat-palette($zf-yellow, A200, A100, A400);
$zf-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$zf-theme: mat-light-theme((
  color: (
    primary: $zf-primary,
    accent: $zf-accent,
    warn: $zf-warn,
  )
));

$primary: mat-color($zf-primary);
$accent: mat-color($zf-accent);
$warn: mat-color($zf-warn);
$textColor2: #FED136;

@include angular-material-theme($zf-theme);

