@import "src/_variables.scss";

.fc-event-appointment {
  background-color: $appointment-background-color !important;
  opacity: 0.7;
  //border-left: 3px solid $appointment-border-color !important;

  .fc-event-main {
    color: $appointment-text-color !important;
  }

  .fc-event-title {
    font-weight: bold;
  }
}


.fc-event-timeslot {
  &.fc-timegrid-event {
    background-color: $timeslot-background-color !important;
    opacity: 0.7;
  }

  &.fc-timeline-event {
    background-color: $timeslot-background-color !important;
    opacity: 0.7;
  }

  &.fc-event-fill-empty {
    border-left: 7px solid $timeslot-empty-border-color !important;
    .fc-list-event-dot {
      border-color: $timeslot-empty-border-color;
    }
  }

  &.fc-event-fill-partial {
    border-left: 7px solid $timeslot-partial-border-color !important;
    .fc-list-event-dot {
      border-color: $timeslot-partial-border-color;
    }
  }

  &.fc-event-fill-full {
    border-left: 7px solid $timeslot-full-border-color !important;
    .fc-list-event-dot {
      border-color: $timeslot-full-border-color;
    }
  }

  &.fc-event-fill-overfilled {
    border-left: 7px solid $timeslot-full-border-color !important;
    .fc-list-event-dot {
      border-color: $timeslot-full-border-color;
    }
  }

  .fc-event-main {
    color: $timeslot-text-color !important;
  }

  .fc-event-title {
    font-weight: bold;
  }
}

.fc-selected {
  &.fc-timeline-event,
  &.fc-timegrid-event {
    box-shadow: 4px 3px 8px 1px #969696 !important;
    -webkit-box-shadow: 4px 3px 8px 1px #969696 !important;
  }
}

